<template>
	<div class="animated fadeIn">
		<b-row>
			<b-col class="col-12">
				<div class="table-responsive">
					<table class="table table-hover table-striped">
						<thead>
							<tr>
								<th class="border-top-0">
									#
								</th>
								<th class="border-top-0 text-left">
									{{ translate('file_name') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('row_number') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('message') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('date_time') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
								</td>
								<td class="align-middle text-left">
									{{ item.attributes.file_name }}
								</td>
								<td class="align-middle text-left">
									{{ item.attributes.row_number }}
								</td>
								<td class="align-middle text-left">
									{{ item.attributes.message }}
								</td>
								<td class="align-middle text-left">
									{{ $moment(item.attributes.created_at.date).format(dateFormat) }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					:has-data="hasData" />
				<b-pagination
					v-if="pagination.total_pages > 1"
					v-model="pagination.current_page"
					:total-rows="pagination.total"
					:per-page="pagination.per_page"
					align="center"
					@change="getDataFiltered" />
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { WarehouseOrders } from '@/translations';
import WarehouseOrdersUtil from '@/util/WarehouseOrders';
import { YMDHMS_FORMAT } from '@/settings/Dates';

export default {
	name: 'WarehouseOrdersExceptions',
	messages: [WarehouseOrders],
	data() {
		return {
			dateFormat: YMDHMS_FORMAT,
			warehouseId: null,
			warehouseExceptions: new WarehouseOrdersUtil(),
		};
	},
	computed: {
		loading() {
			return !!this.warehouseExceptions.data.loading;
		},
		hasData() {
			return this.data.length > 0;
		},
		pagination() {
			return {
				current_page: 1,
				per_page: 10,
				total: 0,
				total_pages: 0,
			};
		},
		data() {
			try {
				return this.warehouseExceptions.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
	},
	mounted() {
		this.warehouseId = this.$route.query.warehouseId;
		this.getData();
	},
	methods: {
		getDataFiltered(page) {
			this.pagination.current_page = page;
			this.getData();
		},
		getData() {
			this.selectAll = false;
			if (this.warehouseId) {
				this.warehouseExceptions.getExceptions(this.warehouseId);
			}
		},
	},
};
</script>
